import moment from 'moment-timezone';

const getUpdatedActivitiesData = (activitiesData, manualTimeRequests, timeZone) => {
    const manualRequests = manualTimeRequests;
    const updatedManualRequests = manualRequests.map((item) => ({
        ...activitiesData[0],
        ActivityEndTime: item.EndTimeDate,
        ActivityTime: item.StartTimeDate,
        SystemStatus: 'idle',
        TotalSeconds: item.ManualTimeInSeconds,
        updatedAt: '',
        ManualTimeInSeconds: item.ManualTimeInSeconds,
    }));
    let ManualRequestIndex;
    const filtered = updatedManualRequests.filter((manualRequest) => {
        const updatedActivitiesData = activitiesData.filter((item, index) => {
            if (
                moment(manualRequest.ActivityTime).isBefore(item.ActivityTime) &&
                moment(item.ActivityEndTime).isBefore(manualRequest.ActivityEndTime)
            ) {
                return true;
            }
            if (!ManualRequestIndex) {
                ManualRequestIndex = index;
            }
            return false;
        });
        if (updatedActivitiesData && updatedActivitiesData.length === 0) {
            return true;
        }
        return false;
    });
    const cloneActivitiesData = [...activitiesData];
    filtered.map((item) => {
        if (item.ManualTimeInSeconds > 300) {
            let FinalData = [];
            for (let i = 0; i < Math.ceil(item.ManualTimeInSeconds / 300); i++) {
                const manualUpdateItem = {
                    ...activitiesData[0],
                    ActivityEndTime:
                        i === 0
                            ? moment(item.ActivityEndTime).tz(timeZone).toISOString()
                            : moment(moment(FinalData[i - 1].ActivityTime).second(300))
                                  .tz(timeZone)
                                  .second(300)
                                  .toISOString(),
                    ActivityTime:
                        i === 0
                            ? moment(item.ActivityTime).toISOString()
                            : moment(FinalData[i - 1].ActivityTime)
                                  .tz(timeZone)
                                  .second(300)
                                  .toISOString(),
                    SystemStatus: 'idle',
                    TotalSeconds: 300,
                    updatedAt: '',
                };
                FinalData = [...FinalData, manualUpdateItem];
                let defindex;
                cloneActivitiesData.filter((element, index) => {
                    if (
                        (moment(element.date).minutes() > moment(manualUpdateItem.date).minutes() &&
                            moment(element.date).hours() >=
                                moment(manualUpdateItem.date).hours()) ||
                        (moment(element.date).minutes() <=
                            moment(manualUpdateItem.date).minutes() &&
                            moment(element.date).hours() > moment(manualUpdateItem.date).hours())
                    ) {
                        if (!defindex) {
                            defindex = index;
                        }
                        return true;
                    }
                    return false;
                });
                cloneActivitiesData.splice(
                    defindex || cloneActivitiesData.length,
                    0,
                    manualUpdateItem,
                );
            }
            return FinalData;
        }
        let defindex;
        cloneActivitiesData.filter((element, index) => {
            if (
                (moment(element.date).minutes() > moment(item.date).minutes() &&
                    moment(element.date).hours() >= moment(item.date).hours()) ||
                (moment(element.date).minutes() <= moment(item.date).minutes() &&
                    moment(element.date).hours() > moment(item.date).hours())
            ) {
                if (!defindex) {
                    defindex = index;
                }
                return true;
            }
            return false;
        });
        cloneActivitiesData.splice(defindex || cloneActivitiesData.length, 0, item);
        return item;
    });
    return cloneActivitiesData;
};

const getUpdatedScreenShotData = (screenShotData, manualTimeRequests, timeZone) => {
    const manualRequests = manualTimeRequests;
    const updatedManualRequests = manualRequests.map((item) => ({
        ...screenShotData[0],
        activeWindow_owner: 'Manual Time',
        activeWindow_title: 'Manual Time',
        date: moment(item.StartTimeDate).tz(timeZone),
        EndTimeDate: moment(item.EndTimeDate).tz(timeZone),
        StartTimeDate: moment(item.StartTimeDate).tz(timeZone),
        ManualTimeInSeconds: item.ManualTimeInSeconds,
        Status: item.Status,
        file: 'ManualRequest',
        username: 'Manual Time',
    }));
    const filtered = updatedManualRequests.filter((manualRequest) => {
        const updatedScreenshotData = screenShotData.filter((item) => {
            if (
                moment(manualRequest.StartTimeDate).isBefore(item.date) &&
                moment(item.date).isBefore(manualRequest.EndTimeDate)
            ) {
                return true;
            }
            return false;
        });
        if (updatedScreenshotData && updatedScreenshotData.length === 0) {
            return true;
        }
        return false;
    });
    const cloneScreenshotData = [...screenShotData];
    filtered.map((item) => {
        if (item.ManualTimeInSeconds > 300) {
            let FinalData = [];
            for (let i = 0; i < Math.ceil(item.ManualTimeInSeconds / 300); i++) {
                const manualUpdateItem = {
                    ...screenShotData[0],
                    activeWindow_owner: 'Manual Time',
                    activeWindow_title: 'Manual Time',
                    time: { hours: 0, minutes: 0, seconds: 300 },
                    date:
                        i === 0
                            ? moment(item.StartTimeDate).tz(timeZone).toISOString()
                            : moment(FinalData[i - 1].date)
                                  .second(300)
                                  .tz(timeZone)
                                  .toISOString(),
                    EndTimeDate: moment(item.EndTimeDate).tz(timeZone),
                    StartTimeDate: moment(item.StartTimeDate).tz(timeZone),
                    file: 'ManualRequest',
                    username: 'Manual Time',
                    Status: item.Status,
                };
                FinalData = [...FinalData, manualUpdateItem];
                let defindex;
                cloneScreenshotData.filter((element, index) => {
                    if (
                        (moment(element.date).minutes() > moment(manualUpdateItem.date).minutes() &&
                            moment(element.date).hours() >=
                                moment(manualUpdateItem.date).hours()) ||
                        (moment(element.date).minutes() <=
                            moment(manualUpdateItem.date).minutes() &&
                            moment(element.date).hours() > moment(manualUpdateItem.date).hours())
                    ) {
                        if (!defindex) {
                            defindex = index;
                        }
                        return true;
                    }
                    return false;
                });
                cloneScreenshotData.splice(
                    defindex || cloneScreenshotData.length,
                    0,
                    manualUpdateItem,
                );
            }
            return FinalData;
        }
        let defindex;
        cloneScreenshotData.filter((element, index) => {
            if (
                (moment(element.date).minutes() > moment(item.date).minutes() &&
                    moment(element.date).hours() >= moment(item.date).hours()) ||
                (moment(element.date).minutes() <= moment(item.date).minutes() &&
                    moment(element.date).hours() > moment(item.date).hours())
            ) {
                if (!defindex) {
                    defindex = index;
                }
                return true;
            }
            return false;
        });
        cloneScreenshotData.splice(defindex || cloneScreenshotData.length, 0, {
            ...item,
            time: { hours: 0, minutes: 0, seconds: item.ManualTimeInSeconds },
        });
        return { ...item, time: { hours: 0, minutes: 0, seconds: item.ManualTimeInSeconds } };
    });
    return cloneScreenshotData.sort((a, b) => (moment(b.date).isBefore(a.date) ? 1 : -1));
};

export { getUpdatedActivitiesData, getUpdatedScreenShotData };
