import { DEPATRTMENT_LIST, DEPATRTMENT_DELETE } from '../actions';

const INIT_STATE = {
    departmentList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEPATRTMENT_LIST: {
            return {
                ...state,
                departmentList: action.payload,
            };
        }
        case DEPATRTMENT_DELETE: {
            const departmentList = [...state.departmentList];
            const manipulatedData = departmentList.filter((user) => user._id !== action.payload);
            return {
                ...state,
                departmentList: manipulatedData,
            };
        }

        default:
            return state;
    }
};
