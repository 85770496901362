import { ALL_ATTENDANCE_LIST, ATTENDANCE_PAGINATION } from '../actions';

const INIT_STATE = {
    allAttendance: [],
    paginationAttendance: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALL_ATTENDANCE_LIST: {
            return {
                ...state,
                allAttendance: action.payload,
            };
        }
        case ATTENDANCE_PAGINATION: {
            return {
                ...state,
                paginationAttendance: action.payload,
            };
        }

        default:
            return state;
    }
};
