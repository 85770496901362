import { DASHBOARD_DATA_MANAGER_EMPLOYEE } from '../actions';

const INIT_STATE = {
    tableList: [],
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get all DashboardTavle Data
        case DASHBOARD_DATA_MANAGER_EMPLOYEE: {
            return {
                ...state,
                tableList: action.payload,
            };
        }

        default:
            return state;
    }
};
