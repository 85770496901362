import {
    FETCH_START,
    FETCH_ERROR,
    FETCH_SUCCESS,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    SIGNUP_BUTTON_ACTIVE,
    SIGNUP_BUTTON_DEACTIVE,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    RESENT_BUTTON_DISABLE,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_CLEAR_ERROR,
    RESET_PASSWORD,
    CREATE_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    CREATE_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CREATE_PASSWORD_ERROR,
    RESET_TOKEN_VERIFICATION,
    CREATE_TOKEN_VERIFICATION,
    RESET_TOKEN_VERIFICATION_SUCCESS,
    CREATE_TOKEN_VERIFICATION_SUCCESS,
    LOGIN_USER_TYPE,
    RESET_TOKEN_VERIFICATION_ERROR,
    CREATE_TOKEN_VERIFICATION_ERROR,
    RESET_TOKEN_VERIFICATION_ERROR_CLEAR,
    CREATE_TOKEN_VERIFICATION_ERROR_CLEAR,
} from '../actions';

const INIT_STATE = {
    user: JSON.parse(localStorage.getItem('userData')) || {},
    token: localStorage.getItem('token'),
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    createPasswordCode: '',
    loading: false,
    error: '',
    resetLinkVerification: false,
    createLinkVerification: false,
    resetUserMail: '',
    createUserMail: '',
    userType: '',
    resentUserMailButton: false,
    signUpButtonDisable: false,
    resetPasswordLinkMessage: null,
    createPasswordLinkMessage: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_START:
            return { ...state, loading: true, error: '' };
        case FETCH_SUCCESS:
            return { ...state, loading: false, error: '' };
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.data,
                token: action.payload.access_token,
                error: '',
            };
        case FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.Message,
            };
        case LOGIN_USER_TYPE:
            return {
                ...state,
                userType: action.payload,
            };
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                user: '',
                error: action.payload.Message,
            };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotUserMail: action.payload,
                error: '',
                resentUserMailButton: true,
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                forgotUserMail: '',
                error: action.payload.Message,
                resentUserMailButton: false,
            };
        case RESENT_BUTTON_DISABLE:
            return {
                ...state,
                resentUserMailButton: false,
            };
        case FORGOT_PASSWORD_CLEAR_ERROR:
            return {
                ...state,
                error: '',
            };
        case RESET_PASSWORD:
            return { ...state, loading: true };
        case CREATE_PASSWORD:
            return { ...state, loading: true };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CREATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                newPassword: '',
                resetPasswordCode: '',
                error: action.payload.Message,
            };
        case CREATE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                newPassword: '',
                createPasswordCode: '',
                error: action.payload.Message,
            };
        case RESET_TOKEN_VERIFICATION:
            return { ...state };
        case CREATE_TOKEN_VERIFICATION:
            return { ...state };
        case RESET_TOKEN_VERIFICATION_SUCCESS:
            return {
                ...state,
                resetLinkVerification: action.payload.Message,
                resetUserMail: action.payload.data,
            };
        case CREATE_TOKEN_VERIFICATION_SUCCESS:
            return {
                ...state,
                createLinkVerification: action.payload.Message,
                createUserMail: action.payload.data,
            };
        case RESET_TOKEN_VERIFICATION_ERROR:
            return {
                ...state,
                resetPasswordLinkMessage: action.payload.Message,
            };
        case CREATE_TOKEN_VERIFICATION_ERROR:
            return {
                ...state,
                createPasswordLinkMessage: action.payload.Message,
            };
        case RESET_TOKEN_VERIFICATION_ERROR_CLEAR:
            return {
                ...state,
                resetPasswordLinkMessage: null,
            };
        case CREATE_TOKEN_VERIFICATION_ERROR_CLEAR:
            return {
                ...state,
                createPasswordLinkMessage: null,
            };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.uid,
                error: '',
                signUpButtonDisable: false,
            };
        case REGISTER_USER_ERROR:
            return {
                ...state,
                loading: false,
                user: '',
                error: action.payload.Message,
                signUpButtonDisable: true,
            };
        case SIGNUP_BUTTON_ACTIVE:
            return {
                ...state,
                signUpButtonDisable: false,
            };
        case SIGNUP_BUTTON_DEACTIVE:
            return {
                ...state,
                signUpButtonDisable: true,
            };
        case LOGOUT_USER:
            return { ...state, user: null, token: null, error: '' };
        default:
            return { ...state };
    }
};
