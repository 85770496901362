import {
    ACTIVITY_REPORTS_DATA,
    USER_ACTIVITY_LIST,
    ALL_USER_ACTIVITY_STATS_DATA,
} from '../actions';

const INIT_STATE = {
    allActivityStats: [],
    allUserActivityList: [],
    allUserActivityStats: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIVITY_REPORTS_DATA: {
            return {
                ...state,
                allActivityStats: action.payload,
            };
        }
        case USER_ACTIVITY_LIST: {
            return {
                ...state,
                allUserActivityList: action.payload,
            };
        }
        case ALL_USER_ACTIVITY_STATS_DATA: {
            return {
                ...state,
                allUserActivityStats: action.payload,
            };
        }
        default:
            return state;
    }
};
