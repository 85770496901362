import {
    REVIVE_COMPANY,
    DELETED_COMPANY,
    ACTIVE_COMPANY,
    DELETE_ACTIVE_COMPANIES,
    ADDED_IN_MONTH,
    DELETED_IN_MONTH,
    DELETE_ADDED_MONTH_COMPANY,
} from '../actions';

const INIT_STATE = {
    deletedCompanyList: [],
    activatedCompanyList: [],
    addedinMonthCompanyList: [],
    deletedinMonthCompanyList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get all Deactive comapnies
        case DELETED_COMPANY: {
            return {
                ...state,
                deletedCompanyList: action.payload,
            };
        }

        // get all active comapnies
        case ACTIVE_COMPANY: {
            return {
                ...state,
                activatedCompanyList: action.payload,
            };
        }

        // get all Added in Month comapnies
        case ADDED_IN_MONTH: {
            return {
                ...state,
                addedinMonthCompanyList: action.payload,
            };
        }

        // Delete Added in Month Company
        case DELETE_ADDED_MONTH_COMPANY: {
            const addedinMonthCompanyList = [...state.companyList];
            const manipulatedData = addedinMonthCompanyList.filter(
                (company) => company.companyId !== action.payload,
            );
            return {
                ...state,
                teamList: manipulatedData,
            };
        }

        // get all Deleted in Month comapnies
        case DELETED_IN_MONTH: {
            return {
                ...state,
                deletedinMonthCompanyList: action.payload,
            };
        }

        // Revive Deleted Company
        case REVIVE_COMPANY: {
            const deletedCompanyList = [...state.deletedCompanyList];
            const manipulatedData = deletedCompanyList.filter(
                (company) => company._id !== action.payload,
            );
            return {
                ...state,
                deletedCompanyList: manipulatedData,
            };
        }

        // Delete Active Comapnies
        case DELETE_ACTIVE_COMPANIES: {
            const activatedCompanyList = [...state.activatedCompanyList];

            const manipulatedData = activatedCompanyList.filter(
                (company) => company.companyId !== action.payload,
            );

            return {
                ...state,
                teamList: manipulatedData,
            };
        }

        default:
            return state;
    }
};
