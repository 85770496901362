import { USER_SCREENSHOT, USER_LIST_SCREENSHOT } from '../actions';
import { getUpdatedActivitiesData, getUpdatedScreenShotData } from './CustomFunction';

const INIT_STATE = {
    userProfile: '',
    screenShot: [],
    userListScreenShot: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_SCREENSHOT: {
            const screenShotData = action.payload
                ? {
                      data: getUpdatedScreenShotData(
                          action.payload.data,
                          action.payload.manualTimeRequests,
                          action.payload.timeZone,
                      ),
                      activitiesData: getUpdatedActivitiesData(
                          action.payload.activitiesData,
                          action.payload.manualTimeRequests,
                          action.payload.timeZone,
                      ),
                      manualTimeRequests: action.payload.manualTimeRequests,
                      timeData: action.payload.timeData,
                  }
                : {};
            return {
                ...state,
                screenShot: screenShotData,
            };
        }
        case USER_LIST_SCREENSHOT: {
            return {
                ...state,
                userListScreenShot: action.payload,
            };
        }
        default:
            return state;
    }
};
