import { DASHBOARD_DATA } from '../actions';

const INIT_STATE = {
    dashboardData: {},
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get all Deactive comapnies
        case DASHBOARD_DATA: {
            return {
                ...state,
                dashboardData: action.payload,
            };
        }
        default:
            return state;
    }
};
