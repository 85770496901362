import { HISTORY_LIST, FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../actions';

const initialState = {
    history: [], // Make sure this is an empty array
    loading: false,
    error: null,
};

const BrowserHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START:
            return { ...state, loading: true };
        case FETCH_SUCCESS:
            return { ...state, loading: false };
        case FETCH_ERROR:
            return { ...state, loading: false, error: action.payload };
        case HISTORY_LIST:
            console.log('Dispatching HISTORY_LIST with payload:', action.payload);
            return { ...state, history: action.payload }; // Correctly setting history
        default:
            return state;
    }
};

export default BrowserHistoryReducer;
