import { MANUAL_REQUEST_LIST } from '../actions';

const INIT_STATE = {
    manualRequest: [],
    paginationManualRequest: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MANUAL_REQUEST_LIST: {
            if (action.payload.paginationManualRequest) {
                return {
                    ...state,
                    manualRequest: action.payload.manualRequest,
                    paginationManualRequest: action.payload.paginationManualRequest,
                };
            }
            return {
                ...state,
                manualRequest: action.payload.manualRequest,
            };
        }
        default:
            return state;
    }
};
