import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';
import reducers from './reducers';
// import sagas from "./sagas";

// const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk];

export function configureStore(initialState) {
    const store = createStore(reducers, initialState, compose(applyMiddleware(...middlewares)));

    // sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
