import { FETCH_ERROR, logoutUser } from '../redux/actions';

export function errorHandler(error, dispatch, history = false, toast = null) {
    if (error.response) {
        if (error.response.status === 401) {
            if (history) {
                history.push('/user/login');
            }
            dispatch(logoutUser(history));
        } else if (error.response.data) {
            dispatch({ type: FETCH_ERROR, payload: error.response.data });
            if (toast !== null) {
                toast.error(error.response.data.Message);
            }
        }
    } else {
        dispatch({ type: FETCH_ERROR, payload: error });
    }
}
