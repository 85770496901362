import {
    ADMIN_PROFILE,
    COMPANY_DETAILS,
    UPDATE_ADMIN_DETAILS,
    UPDATE_COMPANY_DETAILS,
    LOGOUT_USER,
} from '../actions';

const INIT_STATE = {
    adminProfileData: {},
    companyData: {},
    companyTimingDetails: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADMIN_PROFILE: {
            return {
                ...state,
                adminProfileData: action.payload,
                companyData: action.payload.company,
            };
        }
        case UPDATE_ADMIN_DETAILS: {
            return {
                ...state,
                adminProfileData: action.payload,
                companyData: action.payload.company,
            };
        }
        case COMPANY_DETAILS: {
            return {
                ...state,
                companyTimingDetails: action.payload,
            };
        }
        case UPDATE_COMPANY_DETAILS: {
            return {
                ...state,
                companyTimingDetails: action.payload,
            };
        }
        case LOGOUT_USER:
            return { ...state, adminProfileData: {} };
        default:
            return state;
    }
};
