import { DELETED_USER, REVIVE_USER } from '../actions';

const INIT_STATE = {
    deletedUserList: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DELETED_USER: {
            return {
                ...state,
                deletedUserList: action.payload,
            };
        }
        case REVIVE_USER: {
            const deletedUserList = [...state.deletedUserList];
            const manipulatedData = deletedUserList.filter((user) => user._id !== action.payload);
            return {
                ...state,
                deletedUserList: manipulatedData,
            };
        }

        default:
            return state;
    }
};
