import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import screenshot from './screenshot/reducer';
import Departments from './departments/reducer';
import TeamManagement from './teamManagement/reducer';
import Attendance from './attendanceReport/reducer';
import companySetting from './companySetting/reducer';
import DeletedUser from './deletedUser/reducer';
import DeletedCompany from './deletedCompany/reducer';
import Dashboard from './dashboard/reducer';
import Company from './company/reducer';
import ManualRequest from './manualRequest/reducer';
import ActivityReport from './ActivityReport/reducer';
import DashboardManagerandEmployee from './DashboardManagerandEmployee/reducer';
import BrowserHistory from './browserHistory/reducer';

const reducers = combineReducers({
    menu,
    settings,
    authUser,
    screenshot,
    Departments,
    TeamManagement,
    Attendance,
    companySetting,
    DeletedUser,
    DeletedCompany,
    Dashboard,
    Company,
    ManualRequest,
    ActivityReport,
    DashboardManagerandEmployee,
    BrowserHistory,
});

export default reducers;
